import styled from 'styled-components'
import tw from 'twin.macro'

import { Page } from '@/components/page/Page'
import { IdxTampaMapEnglish } from '@/components/homes/IdxTampaMapEnglish'
import { FeaturedHomeDetails } from '@/components/homes/FeaturedHomeDetails'
// import * as redCurrant10208 from '@/homes/10208-redCurrant/info'
import * as elPaseo14584 from '@/homes/14584-elPaseo/info'
import { DefaultPageStyles } from '@/styles/page'
import SEO from '@/utils/SEO'

const HomesStyling = styled(DefaultPageStyles)`
  h3 {
    ${tw`text-left`}
  }
  a:hover {
    ${tw`text-primary-500 underline`}
  }
`

export const HomesPage = () => {
  return (
    <Page>
      <SEO
        title="Tampa Bay Featured Homes and Search"
        description="Find your dream home in Tampa Bay. Browse our featured homes selection or search homes via our Tampa Bay map."
        type="website"
      />
      <HomesStyling>
        <h1>Homes in Tampa Bay</h1>
        <h3>Featured</h3>
        <FeaturedHomeDetails
          data={elPaseo14584.data}
          details={elPaseo14584.details}
        />
        <br />
        <br /> 
        <h2>Search homes in Tampa Bay</h2>
        <IdxTampaMapEnglish />
      </HomesStyling>
    </Page>
  )
}
