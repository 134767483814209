import { HomeData, HomeDetails } from '@/types/homes'
import Paths from '@/config/paths'
import { SEOProps } from '@/types/seo'
import { homeAddressStr } from '@/helpers/homes'

const path = Paths.Home14584elPaseo.loc

export const data: HomeData = {
  mlNumber: "TB8353719",
  status: "Active",
  address: "14584 EL PASEO DR",
  city: "Seminole",
  zip: "33776",
  legalSubdivisionName: "GLADES THE",
  subdivisionCondoName: "GLADES THE",
  heatedArea: "1,928",
  currentPrice: "$450,000",
  beds: 3,
  fullBaths: 2,
  halfBaths: 1,
  yearBuilt: 1992,
  pool: "Community",
  propertyStyle: "Townhouse",
  tax: "", // Not provided
  cdom: 0, // Not provided, assuming 0
  adom: 0, // Not provided, assuming 0
  daysToContract: null, // Not provided
  soldTerms: null, // Not sold yet
  closeDate: null, // Not sold yet
  lpPerSqFt: "$233.40",
  spPerSqFt: "$233.40",
  spPerLp: null, // Not explicitly mentioned
  listOffice: "", // Not provided
  listAgent: "Edith Austin", // Not provided
  listAgentId: "", // Not provided
  sellingOfficeId: null, // Not provided
  lscListSide: "", // Not provided
  listOfficePrimaryBoardId: "", // Not provided
};

export const details: HomeDetails = {
  path,
  sellingPoint: "townhome for sale in The Glades, Seminole, FL. Enjoy breathtaking Intracoastal views, vaulted ceilings, a gourmet kitchen, and resort-style amenities. Minutes from Gulf Beaches! Don't miss this coastal paradise.",
  thumbnailImgStr: '/images/homes/thumbnail/14584-el-paseo-tn.webp',
  info: 'Check out this home today, call me to schedule a viewing (805) 765-1625',
}

export const seo: SEOProps = {
  title: `View home ${homeAddressStr(data)}`,
  description:
    "Stunning waterfront 3-bed, 2.5-bath townhome for sale in The Glades, Seminole, FL. Enjoy breathtaking Intracoastal views, vaulted ceilings, a gourmet kitchen, and resort-style amenities. Minutes from Gulf Beaches! Don't miss this coastal paradise.",
  type: 'article',
  pagePath: path
}
