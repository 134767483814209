import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import GlobalStyles from './styles/GlobalStyles'
import { HelmetProvider } from 'react-helmet-async'
import { useInitiateLanguage } from './helpers/languageHooks'
import Paths from './config/paths'
import HomePage from './pages/home/HomePage'
import { useInitializeGoogleAnalytics } from '@/utils/analytics.ts'
import { AboutPage } from './pages/AboutPage'
import { HomesPage } from './pages/HomesPage'
import { BlogPage } from './pages/BlogPage'
import { BlogCubanSandwichFestival2024 } from './pages/blog/BlogCubanSandwichFestival2024'
import { BlogPropertyTaxes2024 } from './pages/blog/BlogPropertyTaxes2024'
import { BlogVoyageTampa2024 } from './pages/blog/BlogVoyageTampa2024'
import { BlogBenTDavisBeach2024 } from './pages/blog/BlogBenTDavisBeach2024'
import { GetStarted } from './pages/GetStarted'
import { BuyHome } from './pages/BuyHome'
import { SellHome } from './pages/SellHome'
import { Relocate } from './pages/Relocate'
import { Home14584elPaseo } from './pages/Home14584elPaseo'

export default function App() {
  console.log(`App running in ${process.env.REACT_APP_NODEENV} mode...`)

  useInitializeGoogleAnalytics()
  useInitiateLanguage()

  return (
    <>
      <GlobalStyles />
      <HelmetProvider>
        <Router>
          <Routes>
            <Route path={Paths.Home.loc} element={<HomePage />} />
            <Route path={Paths.About.loc} element={<AboutPage />} />
            <Route path={Paths.Homes.loc} element={<HomesPage />} />
            <Route path={Paths.GetStarted.loc} element={<GetStarted />} />
            <Route path={Paths.BuyHome.loc} element={<BuyHome />} />
            <Route path={Paths.SellHome.loc} element={<SellHome />} />
            <Route path={Paths.Relocate.loc} element={<Relocate />} />
            <Route path={Paths.Blog.loc} element={<BlogPage />} />
            <Route
              path={Paths.BlogCubanSandwichFestival2024.loc}
              element={<BlogCubanSandwichFestival2024 />}
            />
            <Route
              path={Paths.BlogPropertyTaxes2024.loc}
              element={<BlogPropertyTaxes2024 />}
            />
            {/* Second route for this blog posting as url changed */}
            <Route
              path={'blog/save-money-property-taxes'}
              element={<BlogPropertyTaxes2024 />}
            />
            <Route
              path={Paths.BlogVoyageTampa2024.loc}
              element={<BlogVoyageTampa2024 />}
            />
            <Route
              path={Paths.BlogBenTDavisBeach2024.loc}
              element={<BlogBenTDavisBeach2024 />}
            />
            <Route
              path={Paths.Home14584elPaseo.loc}
              element={<Home14584elPaseo />}
            />
          </Routes>
        </Router>
      </HelmetProvider>
    </>
  )
}
