import styled from 'styled-components'
import tw from 'twin.macro'
import { HomeData, HomeDetails } from '@/types/homes'
import { homeAddressStr, bathroomFormatterStr } from '@/helpers/homes'

const Styles = styled.div`
  ${tw`
    flex flex-col md:flex-row gap-6
    pb-5
  `}

  .image_container {
    min-height: 10rem;
    min-width: 14rem;

    img {
      max-height: 10rem;
      max-width: 14rem;
      ${tw`m-auto mt-2`}
    }
  }

  h5 {
    ${tw`text-left mb-1 text-xl mt-0`}
  }

  p {
    ${tw`mb-1 text-lg`}
  }
`

interface FeaturedHomeDetailsProps {
  data: HomeData
  details: HomeDetails
}
export const FeaturedHomeDetails = ({
  data,
  details,
}: FeaturedHomeDetailsProps) => {
  const { path, sellingPoint, thumbnailImgStr, info } = details

  const baths = bathroomFormatterStr(data)
  const homeAddress = homeAddressStr(data)
  return (
    <>
      <Styles>
        <div className="image_container">
          <a href={path}>
            <img src={thumbnailImgStr} alt={`photo of ${homeAddress} thumbnail`} />
          </a>
        </div>
        <div className="content_container">
          <div>
            <h5>
              <a href={path}>{homeAddress}</a>
            </h5>
            <p>
              {data.beds} beds, {baths} baths, {sellingPoint}
            </p>
            {info && <p>{info}</p>}
            <p>
              <strong>{data.currentPrice}</strong>
            </p>
          </div>
        </div>
      </Styles>
      <hr />
    </>
  )
}
