import { Page } from '@/components/page/Page'
import HomePdf from '@/homes/14584-elPaseo/14584Customer_Synopsis.pdf'
import { data, seo } from '@/homes/14584-elPaseo/info.ts'
import { YoutubeEmbed } from '@/components/misc/YoutubeEmbed'
import { VerticalMargin } from '@/styles/page'
import { HomeDetailsTable } from '@/components/homes/HomeDetailsTable'
import { homeAddressStr } from '@/helpers/homes'
import SEO from '@/utils/SEO'
import { PDFHome } from '@/components/homes/PDFHome'
import { Image } from '@/components/images'
import HomeImage from '@/images/homes/14584-el-paseo.webp'

export const Home14584elPaseo = () => {
  return (
    <Page>
      <SEO {...seo} />
      <h1>{homeAddressStr(data)}</h1>
      <VerticalMargin>
        <YoutubeEmbed embedId="elbn8PVInmo?si=BxJgA5ZZLyE440Lb" />
      </VerticalMargin>
      <HomeDetailsTable data={data} />
      <hr />
      <br />
      <br />
      <h1>Stunning Waterfront Townhome in The Glades</h1>
    <h2>Your Slice of Paradise Awaits!</h2>
    <p>Welcome to this spacious 3-bedroom, 2.5-bathroom townhome in the highly sought-after gated community of The Glades. Offering 1,972 square feet of light-filled living space, this property presents an exceptional opportunity to embrace Florida's waterfront lifestyle.</p>

    <p>Situated just minutes from pristine Gulf Beaches, this townhome is perfectly positioned to offer breathtaking views of the Intracoastal waterways. Enjoy spectacular sunsets from your private balcony and experience serene waterfront living every day.</p>

    <Image src={HomeImage} alt="Outdoor shot of townhome 14584 El Paseo in Seminole"/>


    <h2>Interior Features:</h2>
    <ul>
        <li>Vaulted ceilings in the living room for an open, airy feel</li>
        <li>Arched windows, and plantation shutters</li>
        <li>Gorgeous hardwood floors throughout the dining and kitchen areas</li>
        <li>Custom wood cabinets and exquisite granite countertops in the kitchen</li>
        <li>Cozy eat-in kitchen space</li>
        <li>Spacious master suite with stunning water views</li>
        <li>Luxurious ensuite bath featuring a Jacuzzi tub, dual sinks, and a separate shower</li>
    </ul>

    <h2>Additional Features:</h2>
    <ul>
        <li>Hurricane shutters for added protection</li>
        <li>2-car garage with ample storage space</li>
    </ul>

    <h2>Community Amenities:</h2>
    <ul>
        <li>Resort-style pool and spa</li>
        <li>Tennis courts</li>
        <li>Full-service clubhouse</li>
        <li>Fishing pier just steps from your door</li>
        <li>Beautifully landscaped grounds in a quiet, serene setting</li>
    </ul>

    <p>Enjoy the best of coastal living in a location that’s close to everything you need—whether it’s a day at the beach or a night out in Tampa or St. Petersburg. This townhome offers the perfect balance of elegance, comfort, and convenience.</p>

    <p><strong>Don't miss your chance to own a slice of paradise in one of Florida's most desirable communities!</strong></p>

    <br />
    <h3>Call me today for more information (805) 765-1625</h3>
    <PDFHome
      src={HomePdf}
      data={data}
    />
    </Page>
  )
}