// changeFreq options: always | hourly | daily | weekly | monthly | yearly | never
const Paths = {
  About: { loc: '/about', changefreq: 'monthly', priority: 0.5 },
  Home: { loc: '/', changefreq: 'daily', priority: 0.7 },
  GetStarted: { loc: '/get-started', changefreq: 'monthly', priority: 0.2 },
  Homes: { loc: '/homes', changefreq: 'daily', priority: 0.1 },
  BuyHome: {
    loc: '/find-your-perfect-home-tampa-bay',
    changefreq: 'monthly',
    priority: 0.4,
  },
  SellHome: {
    loc: '/sell-your-home-tampa-bay',
    changefreq: 'monthly',
    priority: 0.4,
  },
  Relocate: {
    loc: '/relocate-to-tampa-bay',
    changefreq: 'monthly',
    priority: 0.4,
  },
  Blog: { loc: '/blog', changefreq: 'daily', priority: 0.7 },
  BlogCubanSandwichFestival2024: {
    loc: '/blog/cuban-sandwich-festival-tampa-2024',
    changefreq: 'monthly',
    priority: 0.2,
  },
  BlogPropertyTaxes2024: {
    loc: '/blog/save-money-property-taxes-tampa-bay',
    changefreq: 'monthly',
    priority: 0.2,
  },
  BlogVoyageTampa2024: {
    loc: '/blog/meet-edith-austin-tampa',
    changefreq: 'monthly',
    priority: 0.2,
  },
  BlogBenTDavisBeach2024: {
    loc: '/blog/ben-t-davis-beach-tampa-bay',
    changefreq: 'monthly',
    priority: 0.2,
  },
  Home14584elPaseo:{
    loc: '/homes/14584-el-paseo-seminole',
    changefreq: 'weekly',
    priority: 0.6,
  }
}

Object.freeze(Paths)

module.exports = Paths
